<template>
  <div class="contenedor-imagenes">
    <img
      src="https://res.cloudinary.com/ddko88otf/image/upload/v1729702865/expo_avprie.png"
      alt="hombre"
      class="imagenderecha"
    />
    <img
      src="https://res.cloudinary.com/ddko88otf/image/upload/v1729702865/maraton_l7y4ed.png"
      alt="hombre"
      class="imagenIzquierda"
    />
  </div>
</template>
<script>
export default {
  name: "PromocionesComponent",
};
</script>
<style scoped>
.contenedor-imagenes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1138px;
  height: auto;
  /* margin-top: 3%; */
  gap: 62.608px;
  margin: auto;
  margin-top: 60px;
  justify-content: center;
  overflow: hidden;
}
.imagenderecha {
  width: 557px;
  height: 250px;
  border-radius: 30.356px;
  object-fit: fill;
}
.imagenIzquierda {
  width: 557px;
  height: 250px;
  border-radius: 30.356px;
  object-fit: fill;
}

@media (max-width: 1200px) {
  .contenedor-imagenes {
    width: 86%;
    height: auto;
    flex-direction: column;
  }
  .imagenderecha {
    width: 100%;
    height: 100%;
    border-radius: 30.356px;
  }
  .imagenIzquierda {
    width: 100%;
    height: 100%;
    border-radius: 30.356px;
  }
}
@media (max-width: 770px) {
  .contenedor-imagenes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 325px;
    height: auto;
    /* justify-content: space-between; */
    /* margin-top: 3%; */
    gap: 21px;
    margin: auto;
    margin-top: 21px;
    justify-content: center;
    overflow: hidden;
  }
  .imagenderecha {
    width: 100%;
    height: 132px;
    border-radius: 15px;
    object-fit: fill;
  }
  .imagenIzquierda {
    width: 100%;
    height: 132px;
    object-fit: fill;
    border-radius: 15px;
  }
}
</style>
