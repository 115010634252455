<template>
  <div class="linea-footer"></div>
  <footer>
    <div class="telefonos-container">
      <img
        src="./../../assets/images/LogoBlancoLuque.svg"
        alt="logo"
        class="logo"
      />
      <div class="telefonos">
        <div class="telefono">
          <h6>CENTRO DE SALUD</h6>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: -10px;
            "
          >
            <i class="bi bi-telephone-fill asd" style="margin-top: -15px"></i>
            <h1>107</h1>
          </div>
        </div>

        <div class="linea"></div>
        <div class="telefono">
          <h6>POLICÍA</h6>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: -10px;
            "
          >
            <i class="bi bi-telephone-fill asd" style="margin-top: -15px"></i>
            <h1>101</h1>
          </div>
        </div>
        <div class="linea"></div>
        <div class="telefono">
          <h6>BOMBEROS</h6>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: -10px;
            "
          >
            <i class="bi bi-telephone-fill asd" style="margin-top: -15px"></i>
            <h1>100</h1>
          </div>
        </div>
      </div>
      <div class="divDireccion">
        <h5 class="direccion">
          Municipalidad de Luque <br />
          9 de julio 806, Luque, Córdoba <br />
          CP X5967 | TEL.: 03573 48-0113
        </h5>
      </div>
    </div>
    <div class="footerMobile">
      <div class="telefonos">
        <div class="telefono">
          <h6>CENTRO DE SALUD</h6>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: -10px;
            "
          >
            <i class="bi bi-telephone-fill asd" style="margin-top: -15px"></i>
            <h1>107</h1>
          </div>
        </div>

        <div class="linea"></div>
        <div class="telefono">
          <h6>POLICÍA</h6>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: -10px;
            "
          >
            <i class="bi bi-telephone-fill asd" style="margin-top: -15px"></i>
            <h1>101</h1>
          </div>
        </div>
        <div class="linea"></div>
        <div class="telefono">
          <h6>BOMBEROS</h6>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin-top: -10px;
            "
          >
            <i class="bi bi-telephone-fill asd" style="margin-top: -15px"></i>
            <h1>100</h1>
          </div>
        </div>
      </div>
      <div class="divDireccion">
        <h5>
          Municipalidad de Luque <br />
          9 de julio 806, Luque, Córdoba <br />
          CP X5967 | TEL.: 03573 48-0113
        </h5>
      </div>
      <img src="@/assets/images/LogoLuque.svg" alt="logo" class="logo" />
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
footer {
  background: var(--blanco, #fff);

  padding: 30.356px 94px;
  display: flex;
}
h1 {
  color: var(--Gris-medio, #9b9a9a);
  text-align: center;
  font-family: Montserrat;
  font-size: 30.925px;
  font-style: normal;
  font-weight: 900;
}
.asd {
  width: 17.37px;
  height: 17.373px;
  color: var(--Gris-medio, #9b9a9a);
}
.linea-footer {
  height: 7.5px;
  background: var(--bordeDegradeCompleto);
  width: 100%;
}
.linea {
  height: 56px;
  width: 1px;
  background: var(--Gris-medio, #9b9a9a);
}
/* h4 {
  font-weight: 400;
  line-height: normal;
} */
.telefonos-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.telefonos {
  display: flex;
  align-items: center;
  gap: 17.373px;
}
.telefono {
  text-align: center;
  margin-top: 10px;
}
.telefono h6 {
  color: var(--Gris-medio, #9b9a9a);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.logo {
  width: 201.546px;
  height: 62.33px;
}
.divDireccion {
  width: 244px;
}

.divDireccion h5 {
  text-align: right;
  color: var(--Gris-medio, #9b9a9a);
  font-family: Montserrat;
  font-size: 15.067px;
  font-style: normal;
  font-weight: 400;
}
.foterMobile {
  display: none;
}
.direccionMobile {
  display: none;
  width: 100%;
}
.footerMobile {
  display: none;
}
@media (max-width: 1200px) {
  .telefonos-container {
    width: auto;
  }
  .divDireccion {
    width: auto;
  }
  .direccion {
    width: 60%;
  }
  .logo {
    width: 25%;
  }
}
@media (max-width: 990px) {
  .direccion {
    text-align: center;
  }
}
@media (max-width: 770px) {
  .logo {
    margin: auto;
    width: 128px;
    height: 40px;
  }
  .footerMobile {
    display: block;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 31px;
    justify-content: center;
    padding-bottom: 29px;
  }
  .divDireccion h5 {
    text-align: center;
    width: 244px;
    margin: auto;
    font-size: 15px;
  }
  footer {
    height: 100px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .telefonos-container {
    display: none;
  }

  .telefonos {
    margin: auto;
    width: 100%;
    justify-content: center;
    /* background: linear-gradient(
      90deg,
      #00c3a8 0.01%,
      #ffb900 54.81%,
      #ff2745 104.96%
    ); */
  }
  .telefonos h6 {
    font-size: 9px;
  }
  h1 {
    font-size: 29.346px;
  }
}
</style>
