<template>
  <div class="general">
    <div class="contenedor">
      <h1 class="tituloGestiones">Gestiones más usuales</h1>
      <div class="linea"></div>
      <!-- MOBILE -->
      <div class="carousel-mobile">
        <p
          style="
            font-size: 40px;
            position: absolute;
            left: 15px;
            color: black;
            top: 50px;
          "
          @click="atras"
          v-if="uno > 1"
        >
          <img src="./../../assets/images/atras.svg" alt="" />
        </p>
        <div class="cardGestiones">
          <router-link
            :to="`/tramites-servicios/guia-tramites/${gestiones[uno]?.title}/${gestiones[uno]?.id}`"
            class="link"
          >
            <div class="card-body">
              <div>
                <img :src="gestiones[uno]?.icon" alt="" />
              </div>
              <div class="titulo">
                <h5>{{ gestiones[uno]?.title }}</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="cardGestiones" v-if="dos <= 5">
          <router-link
            :to="`/tramites-servicios/guia-tramites/${gestiones[dos]?.title}/${gestiones[dos]?.id}`"
            class="link"
          >
            <div class="card-body">
              <div>
                <img :src="gestiones[dos]?.icon" alt="" />
              </div>
              <div class="titulo">
                <h5>{{ gestiones[dos]?.title }}</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="cardGestiones" v-else>
          <router-link to="/tramites-servicios/pagar-tasas" class="link">
            <div class="card-body">
              <div>
                <img
                  src="https://res.cloudinary.com/ddko88otf/image/upload/v1728485237/18_hypdoy.svg"
                  alt=""
                />
              </div>
              <div class="titulo">
                <h5>Pagar Tasas</h5>
              </div>
            </div>
          </router-link>
        </div>
        <p
          style="
            font-size: 40px;
            position: absolute;
            right: 15px;
            color: black;
            top: 50px;
          "
          v-if="dos < 7"
          @click="siguiente"
        >
          <img src="./../../assets/images/siguiente.svg" alt="" />
        </p>

        <!-- <carousel :items-to-show="1" :wrapAround="true" :transition="500">
        <slide v-for="slide in this.gestiones" :key="slide">
          <router-link to="/tramites-servicios/guia-tramites">
            <img :src="slide.icon" alt="" />
            <h5>{{ slide.title }}</h5>
          </router-link>
        </slide>
      </carousel> -->
      </div>

      <!-- WEB -->
      <div v-if="gestiones.length > 0">
        <div class="gridcontainer">
          <div class="cardGestiones">
            <router-link to="/tramites-servicios/pagar-tasas" class="link">
              <div class="card-body">
                <div>
                  <img
                    src="https://res.cloudinary.com/ddko88otf/image/upload/v1728485237/18_hypdoy.svg"
                    alt=""
                  />
                </div>
                <div class="titulo">
                  <h5>Pagar Tasas</h5>
                </div>
              </div>
            </router-link>
          </div>

          <div
            v-for="(item, index) in gestiones"
            :key="index"
            class="cardGestiones"
          >
            <router-link
              :to="`/tramites-servicios/guia-tramites/${item.title}/${item.id}`"
              class="link"
            >
              <div class="card-body">
                <div><img :src="item.icon" alt="" /></div>
                <div class="titulo">
                  <h5>
                    {{ item.title }}
                  </h5>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="masGestiones">
        <router-link :to="'/tramites-servicios/guia-tramites'" class="link">
          <p>Ingresá aquí para ver todas las gestiones</p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
// import { Carousel, Slide } from "vue3-carousel";
// import { BASE_URL } from "@/env";
import { BASE_URL } from "@/env";
import axios from "axios";

export default {
  name: "TarjetasComponent",
  components: {
    // Carousel,
    // Slide,
    // Navigation,
  },
  created() {
    this.getCategorias();
  },
  data() {
    return {
      uno: 0,
      dos: 1,
      gestiones: [
        // {
        //   titulo: "Pagar Tasas",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/pagarTasas_brlaee.svg",
        // },
        // {
        //   titulo: "Habilitaciones Comerciales",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700311/habilitacion_c4xviq.svg",
        // },
        // {
        //   titulo: "Licencias de Conducir",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700311/licenciaCondu_fyphnr.svg",
        // },
        // {
        //   titulo: "Obras Privadas",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/obrasPri_tdk8re.svg",
        // },
        // {
        //   titulo: "Catastro Municipal",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/catastroMuni_qh7ej8.svg",
        // },
        // {
        //   titulo: "Registro Civil",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/registro_dikezr.svg",
        // },
        // {
        //   titulo: "Plazas y Espacios Verdes",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/plazaEspacio_p3qe2y.svg",
        // },
        // {
        //   titulo: "Calles y Veredas",
        //   imagen:
        //     "https://res.cloudinary.com/ddko88otf/image/upload/v1729700310/callesYvere_khigwt.svg",
        // },
      ],
      hover: false,
    };
  },
  methods: {
    getCategorias() {
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient
        .get("/oficina/categories/categories")
        .then((response) => {
          // console.log(response.data);
          let categorias = response.data.Categories.Procedures;

          let servicios = response.data.Categories.Services;
          // let impuestos = response.data.Categories.taxArray;

          // this.impuestos[0].title = "Tasas e Impuestos";
          // this.impuestos[0].description =
          // // "Consultá y pagá, inscribíte, hacé presentaciones, solicitá informes y más. ¡Tu contribución es clave para seguir mejorando nuestra comunidad!.";
          let obras = response.data.Categories.workArray;
          // this.obras[0].title = "Catastro y Obras Privadas";
          // this.obras[0].description =
          // "Realizá consultas y gestiones en el Catastro Municipal, como también permisos y trámites para obras privadas. Todo fácil, rápido y en un solo lugar.";
          let transito = response.data.Categories.licencesArray;

          this.gestiones = [
            categorias[0],
            categorias[1],
            transito[0],
            servicios[0],
            servicios[1],
            obras[0],
            obras[1],
          ];
          // console.log(this.gestiones, "asoy las getionews");
          // for (let index = 0; index < 4; index++) {
          //   this.gestiones.push(this.categorias[index]);
          // }
          // this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setClass() {
      this.hover = true;
    },
    siguiente() {
      if (this.uno <= 6) {
        this.uno = this.uno + 2;
        if (this.dos <= 5) {
          this.dos = this.dos + 2;
        } else {
          this.dos = 5;
        }
      } else {
        this.uno = 6;
      }
      // console.log(this.uno, this.dos);
    },
    atras() {
      // if (this.uno == 0) {
      //   this.uno = 6;
      //   this.dos = 7;
      // } else {
      this.uno = this.uno - 2;
      this.dos = this.dos - 2;
      // console.log(this.uno, this.dos);

      // }
    },
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.general {
  width: 1138px;
  height: 476px;
  margin: auto;
  background: var(--bordeDegradeCompleto);
  padding-bottom: 4px;
  border-radius: 22px;
  margin-bottom: 60px;
}
.contenedor {
  position: relative;
  background: #fff;
  color: rgba(255, 255, 255, 0.432);
  width: 100%;
  height: 100%;
  padding: 41px 0px;
  background-image: url("./../../assets/images/Frame.svg");
  /* opacity: 0.2; */
  background-repeat: space;
  background-position: center;
  text-align: center;
  overflow: hidden;
  border-radius: 22px;

  /* filter: blur(25px); */
}
.tituloGestiones {
  color: #707070;
  text-align: center;
  font-family: Montserrat;
  font-size: 47.431px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}
.top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 8%;
  background: linear-gradient(
    90deg,
    #00c3a8 0.18%,
    #ffb900 52.79%,
    #ff2745 107.64%
  );
  text-align: center;
  /* padding: 2%; */
}
.top h6 {
  visibility: hidden;
}
/* .buscador {
  position: absolute;
  width: 30%;
  height: 8%;
  background: #00c3a8;
  top: 0;
  right: 0;
  border-bottom-left-radius: 50px;
  text-align: left;
  padding-left: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
} */
label {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
input {
  background: none;
  border-style: none;
  margin-left: 0.5rem;
  margin-right: 1rem;
  width: 60%;
}
.gridcontainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 978px;
  gap: 15.18px;
  margin: auto;
  margin-top: 35px;
  margin-bottom: 35px;
}
h5 {
  color: #3e3e3e;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 3%;
}
.cardGestiones {
  background: #fff;
  border-radius: 16px;
  border: 1px solid var(--grisMedio);
  height: 96.758px;
  width: 233.358px;
  margin: auto;
}
.link {
  text-decoration: none;
  color: inherit;
}
.card-body {
  display: flex;
  flex-direction: row;
  gap: 11.35px;
  align-items: center;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  text-align: left;
  background: white;
  justify-content: center;
}
.card-body img {
  width: 43px;
  height: 43px;
}
.cardGestiones:hover {
  background: var(--bordeDegradeCompleto);
  border: 0.237px solid var(--bordecard);
  padding: 2px;
  border: none;
  /* width: 110%; */
  /* position: relative; */
}
.titulo {
  width: 60%;
}
.masGestiones {
  border-radius: 30.356px;
  background: #f5f5f5;
  height: 50px;
  width: 393px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding-top: 1%; */
}
.masGestiones p {
  color: #707070;
  font-family: Montserrat;
  font-size: 16.601px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* .lineab {
  position: absolute;
  height: 6px;
  width: 100%;
  background: linear-gradient(
    270deg,
    #e52320 9.64%,
    #ffcc03 55.98%,
    #019939 87.68%
  );

  bottom: 0;
} */

.linea {
  height: 6px;
  background: linear-gradient(
    to right,
    #ff6928,
    #ff8219,
    #ff9a08,
    #ecab00,
    #d7b909,
    #c2c627,
    #96ca4b,
    #6ccb6d,
    #3fc88d,
    #00c3a8
  );
  width: 630px;
  border-radius: 100%;
  margin: auto;
}

.carousel-mobile {
  display: none;
}
@media (max-width: 1200px) {
  .general {
    width: 100%;
  }
}
@media (max-width: 770px) {
  .tituloGestiones {
    font-size: 13px;
  }
  .linea {
    width: 160px;
    height: 3.5px;
  }
  .general {
    width: 330px;
    height: 281px;
    margin: auto;
    background: var(--bordeDegradeCompleto);
    padding-bottom: 2px;
    border-radius: 13px;
    margin-bottom: 21px;
  }
  .contenedor {
    position: relative;
    background: #fff;
    color: rgba(255, 255, 255, 0.432);
    width: 100%;
    height: 100%;
    padding: 20px 0px;
    background-image: url("./../../assets/images/Frame.svg");
    background-repeat: space;
    background-position: center;
    text-align: center;
    overflow: hidden;
    border-radius: 11px;
  }
  .gridcontainer {
    display: none;
  }
  .masGestiones {
    display: none;
  }
  .carousel-mobile {
    display: block;
    width: 100%;
    height: auto;
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    position: relative;
  }
  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel__slide {
    padding: 1% 1%;
    background: #fff;
    min-height: 61px;
    min-width: 146px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0.5rem;
  }
  /* .contenedor {
    background-image: url("./../../assets/images/FrameMobile.svg");
    padding-top: 0;
    width: 90%;
    height: 130px;
    border-radius: 15px 0px 0px 0px;
    position: relative;
  }
  .top {
    width: 100%;
    background: #00c3a8;
    height: 31px;
    padding-top: 2%;
  }
  .top h6 {
    visibility: visible;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #fff;
  }

  .contenedor h1 {
    font-size: 14px;
    visibility: hidden;
  }
  .buscador {
    display: none;
  }
  h5 {
    color: #4b4a49;
    font-weight: 700;
  }
  .carousel-mobile {
    display: block;
    width: 100%;
    height: auto;
    margin-top: -7%;
  }
  .gridcontainer {
    display: none;
  }
  .carousel {
    width: 100%;
    height: 100%;
  }
  .carousel__slide {
    padding: 1% 1%;
    background: #fff;
    min-height: 61px;
    min-width: 146px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0.5rem;
  }
  .carousel__slide:hover {
    background: linear-gradient(180deg, #019939 4.26%, #ffcc03 126.04%);
  }
  .hovermobile {
    background: linear-gradient(180deg, #019939 4.26%, #ffcc03 126.04%);
  }
  .interior {
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .interior h5 {
    font-size: 11px;
  }
  .interior h5:hover {
    color: #019939;
  }
  .interior img {
    height: 34px;
    width: 34px;
  }
  .masGestiones {
    display: none;
  }
  .tituloGestiones {
    font-size: 15px;
    color: #00c3a8;
  } */
}
</style>
