<template>
  <div class="contenedor">
    <div class="izquierdo"></div>
    <h1>{{ titulo }}</h1>
    <div class="derecho"></div>
  </div>
</template>
<script>
export default {
  name: "TituloComponent",
  props: {
    titulo: String,
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.contenedor {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: auto;
  /* margin-top: 60px; */
  /* margin-bottom: 60px; */
  align-items: flex-end;
}

.contenedor h1 {
  color: var(--Gris-oscuro, #3e3e3e);
  text-align: center;
  font-family: Montserrat;
  font-size: 16.601px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.767px; /* 137.143% */
  text-transform: uppercase;
}

.izquierdo {
  width: 37%;
  height: 1px;
  background: #4b4a49;
  margin-bottom: 1%;
}

.derecho {
  height: 1px;
  width: 37%;
  background: #4b4a49;
  margin-bottom: 1%;
}
@media (max-width: 770px) {
  .linea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .contenedor {
    width: 100%;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
    align-items: center;
  }
  .contenedor h1 {
    font-size: 13.101px;
    font-style: normal;
    font-weight: 400;
  }
  .izquierdo {
    height: 1px;
    width: 15%;
    margin-bottom: 0%;
    background: #4b4a49;
  }
  .derecho {
    height: 1px;
    width: 15%;
    margin-bottom: 0%;
    background: #4b4a49;
  }
}
</style>
