<template>
  <div class="contenedor-imagen">
    <div class="capa"></div>
    <div class="text-izquierdo">
      <h3 style="color: white">
        Presentá todas tus <br />gestiones y trámites de <br />manera digital,
        rápido y<br />simple. <br />
        <br /><strong
          >Sin horarios y desde el <br />lugar que vos elijas.</strong
        >
      </h3>
    </div>
    <div class="text-derecho">
      <img src="@/assets/images/textoChica.svg" alt="" class="textoWeb" />
    </div>
    <div class="muni-linea">
      <img src="@/assets/images/Muni-linea.svg" alt="" />
    </div>
    <div class="linea-b"></div>
  </div>
</template>
<script>
export default {
  name: "ImagenComponent",
};
</script>
<style scoped>
h3 {
  color: white;
  font-size: 24px;
}
.contenedor-imagen {
  position: relative;
  width: 1138px;
  height: 531px;
  margin: auto;
  border-radius: 60px 0px 60px 0px;
  background: linear-gradient(
    272deg,
    #000 -23.75%,
    rgba(0, 0, 0, 0) 55.14%,
    #000 114.38%
  );

  background-image: url("@/../public/images/ImagenMujer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  /* filter: brightness(0.7); */
  /* z-index: 1; */
  overflow: hidden;
  object-fit: contain;
}
.capa {
  position: absolute;
  border-radius: 60px 0px 60px 0px;
  background-size: contain;

  width: 100%;

  height: 531px;

  background: linear-gradient(
    272deg,
    #000 -23.75%,
    rgba(0, 0, 0, 0) 55.14%,
    #000 114.38%
  );
}
.text-izquierdo {
  position: absolute;
  margin-left: 3%;
  margin-top: 5%;
  width: 30%;
  z-index: 2;
}
.text-derecho {
  position: absolute;
  right: 9%;
  top: 24%;
  z-index: 2;
}
.text {
  color: white;
  font-weight: 900;
  font-size: 48px;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
  /* line-height: normal; */
}
.grande {
  font-size: 88px;
  font-weight: 400;
  color: white;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
  /* line-height: normal; */
  margin-top: -12%;
}
.muni-linea {
  position: absolute;
  right: 12%;
  bottom: 38%;
  width: 14%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 34.495px;
  background: var(
    --Degrad-completo,
    linear-gradient(90deg, #00c3a8 0.01%, #ffb900 54.81%, #ff2745 104.96%)
  );
}
.muni-linea img {
  height: 7vh;
  /* width: 30vw; */
}
.linea-b {
  position: absolute;
  height: 6px;
  width: 100%;
  background: linear-gradient(
    270deg,
    #e52320 9.64%,
    #ffcc03 55.98%,
    #019939 87.68%
  );

  bottom: 0;
}
@media (max-width: 1200px) {
  .contenedor-imagen {
    width: 100%;
    margin: auto;
  }
}
@media (max-width: 770px) {
  .contenedor-imagen {
    width: 325px;
    height: 171px;
    background-image: url("@/../public/images/ImagenMujerDos.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0px 0px 16px 0px;
    position: relative;
  }
  .capa {
    height: 171px;
    width: 100%;
    background: linear-gradient(270deg, #000 -26.3%, rgba(0, 0, 0, 0) 69.02%);
    border-radius: 0px 0px 16px 0px;
  }

  .text-izquierdo h3 {
    display: none;
  }
  .linea-b {
    display: none;
  }
  .text-derecho {
    /* display: none;  */
    width: 151px;
    height: 66px;
    top: 27px;
    right: 24px;
  }
  .muni-linea {
    position: absolute;
    right: 27px;
    bottom: 20px;
    width: 129px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 34.495px;
    background: var(
      --Degrad-completo,
      linear-gradient(90deg, #00c3a8 0.01%, #ffb900 54.81%, #ff2745 104.96%)
    );
  }
  .muni-linea img {
    height: auto;
  }
  .text-derecho img {
    width: 100%;
  }
  /* .text-derecho {
    width: 25%;
  }
  .text-derecho img {
    width: 100%;
  } */
  /* .grande {
    font-size: 24px;
  }
  .text {
    font-size: 14px;
  } */
  /* .muni-linea {
    border-radius: 16px;
    background: var(
      --Degrade-VA,
      linear-gradient(106deg, #00c3a8 0%, #ffb900 101.17%)
    );
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.5);
    width: 60px;
    height: 23px;
    right: 10%;
    bottom: 35%;
  } */
  /* .muni-linea img {
    width: 50%;
  } */
  /* .linea-b {
    visibility: hidden;
  }
  br {
    display: none;
  } */
}
</style>
